import React from 'react'
import { TextCaption } from '../composing/CSText'
import { commentsMarkdown, Formatter } from '../scopes/text-editor/csMarkdown'
import HorizontalStroke from '../composing/HorizontalStroke'
import { Colors } from '../theme/constants'
import { Spacing } from '../composing/Spacing'
import { CommentSummary, ParentTypes } from '@commonstock/common/src/api/comment'
import UserBlock from './UserBlock'
import MentionsContext from '../scopes/text-editor/Mentions'
import { getFirstCommentFromUser } from 'src/scopes/comments/CommentCardSmall'

function CardCommentSummary({
  comments,
  commentTarget
}: {
  comments: CommentSummary
  parentType: ParentTypes
  commentTarget?: string
}) {
  let comment = commentTarget ? getFirstCommentFromUser(comments, commentTarget) : comments?.comments[0]

  if (!comment) return null
  return (
    <div>
      <Spacing margin={[1, 0]}>
        <HorizontalStroke />
      </Spacing>
      <div>
        <UserBlock user={comment.user} singleLine timestamp={comment.created_at} />
        <MentionsContext.Provider value={comment}>
          <Formatter text={comment?.body} mentions={comment} processor={commentsMarkdown} clampLine={1} />
        </MentionsContext.Provider>
      </div>
      {comments.count > 1 ? (
        <TextCaption color={Colors.TextSecondary}>
          View {comments.count - 1} more {comments.count - 1 > 1 ? 'comments' : 'comment'}
        </TextCaption>
      ) : (
        <TextCaption color={Colors.TextSecondary}>Add a comment…</TextCaption>
      )}
    </div>
  )
}

export default React.memo(CardCommentSummary)
